@page {
    size: A5;
    margin: 8mm;
}
@media print{
    body{
        text-transform: uppercase;
    }
    .MuiCheckbox-root{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .MuiTypography-root{
        font-size: 1rem !important;
        font-weight: 500 !important;
        font-family: "Roboto Mono", 'Courier New', Courier, monospace !important;
        color: black !important;
    }
}