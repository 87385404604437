.fruit-loader .comet{
  width: 0rem;
  height: 0rem;
  position: absolute;
  right: 0px;
  animation: move 10.2s infinite;
  animation-delay: calc(var(--j)*1.7);
  animation-timing-function: cubic-bezier(1, 0.8, 0.8, 1)
}

.fruit-loader{
  width: 100%;
  position: fixed;
}

@keyframes move{
  0%{
    right: 0%;
    width: 0rem;
    height: 0rem;
  }

  50%{
    right: calc(50% - 2rem);
    width: 4rem;
    height: 4rem;
  }
  
  100%{
    right: 100%;
    width: 0rem;
    height: 0rem;
  }
}