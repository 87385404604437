@page {
    size: A5;
    margin: 8mm;
}
@media print{
    body{
        text-transform: uppercase;
    }
    .MuiDataGrid-root, .MuiDataGrid-main, .MuiDataGrid-virtualScrollerRenderZone{
        display: block !important;
    }
    .MuiDataGrid-virtualScrollerContent{
        min-height: auto !important;
        height: fit-content !important;
    }
    .MuiDataGrid-virtualScrollerRenderZone{
        position: relative !important;
    }
    .order-details-container .sale-rows{
        order: 8;
    }
    .order-details-container .price-row{
        order: 7;
    }
    .MuiCheckbox-root{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .MuiDataGrid-row, .MuiDataGrid-cell{
        max-height: fit-content !important;
        min-height: 40px !important;
    }
    .MuiSelect-select{
        font-size: 1rem !important;
        padding: 0 !important;
    }
    .MuiOutlinedInput-root{
        color: black !important;
        font-family: "Roboto Mono", 'Courier New', Courier, monospace !important;
    }
    .MuiOutlinedInput-root svg{
        display: none;
    }
    .MuiOutlinedInput-notchedOutline, .MuiTypography-root{
        border: none !important;
    }
    .MuiTypography-root{
        font-size: 1rem !important;
        font-weight: 500 !important;
        font-family: "Roboto Mono", 'Courier New', Courier, monospace !important;
        color: black !important;
    }
    .MuiPaper-root{
        box-shadow: none !important;
    }
    .MuiDataGrid-root{
        font-family: "Roboto Mono", 'Courier New', Courier, monospace !important;
    }
    .MuiDataGrid-root, .MuiDataGrid-virtualScroller, .MuiDataGrid-columnHeaders{
        width: 148mm !important;
    }
    .MuiDataGrid-root .MuiDataGrid-cell, .MuiDataGrid-root .MuiDataGrid-columnHeader{
        width: fit-content !important;
        min-width: 28mm !important;
    }
    .MuiDataGrid-root .MuiDataGrid-cell:first-of-type,
    .MuiDataGrid-root .MuiDataGrid-columnHeader:first-of-type{
        min-width: 13mm !important;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader:first-of-type{
        opacity: 0;
    }
    .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(2),
    .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(2){
        min-width: 22mm !important;
        max-width: 22mm !important;
    }
    .no-prices .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(2),
    .no-prices .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(2){
        min-width: 35mm !important;
    }
    .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(3),
    .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(3){
        min-width: 40mm !important;
        max-width: 40mm !important;
    }
    .no-prices .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(3),
    .no-prices .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(3){
        min-width: 40mm !important;
        max-width: 40mm !important;
    }
    .MuiDataGrid-root .MuiDataGrid-cell .MuiDataGrid-cellContent{
        word-break: break-word;
        white-space: break-spaces;
    }
    .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(4),
    .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(4){
        min-width: 20mm !important;
    }
    .MuiGrid-grid-md-2{
        flex-basis: 29mm !important;
    }
    .MuiGrid-item{
        padding-top: 5mm !important;
    }
    .no-prices .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(5),
    .no-prices .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(5),
    .no-prices .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(6),
    .no-prices .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(6),
    .no-prices .price-row{
        display: none !important;
    }
    .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(5),
    .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(5),
    .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(6),
    .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(6){
        max-width: 27mm !important;
        min-width: 27mm !important;
    }
    .no-prices .MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(3),
    .no-prices .MuiDataGrid-root .MuiDataGrid-columnHeader:nth-of-type(3){
        min-width: 79mm !important;
    }
}